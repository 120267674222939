import { replaceString } from "@mybitzer-workspace/shared"

export enum ActivityReferenceType {
    SalesOrder = 'sales-order',
    BDNEquipment = 'bdn-equipment'
}

export const ActivityReferenceRoutes: Record<ActivityReferenceType, string> = {
    [ActivityReferenceType.SalesOrder]: 'portal/sales/board/details/{id}',
    [ActivityReferenceType.BDNEquipment]: 'equipment/{id}/status'
}

export const ActivityReferenceIcons: Record<ActivityReferenceType, string> = {
    [ActivityReferenceType.SalesOrder]: 'list_alt',
    [ActivityReferenceType.BDNEquipment]: 'precision_manufacturing'
}

export function getActivityRoute(referenceType: string, referenceId: string) {
    const route = ActivityReferenceRoutes[referenceType as ActivityReferenceType] || '/not-found'
    return replaceString(route, '{id}', referenceId)
}