import { Component } from '@angular/core'
import { debounceTime, distinctUntilChanged, filter, tap } from "rxjs/operators"
import { takeUntilDestroyed } from "@angular/core/rxjs-interop"
import { Subject } from "rxjs"
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { TranslatePipe } from '@ngx-translate/core'
import { HomeService } from '@mybitzer-workspace/shared'

@Component({
    selector: 'app-main-search',
    templateUrl: './main-search.component.html',
    styleUrl: './main-search.component.scss',
    imports: [MatFormField, MatLabel, MatInput, MatIconButton, MatSuffix, MatIcon, TranslatePipe]
})
export class MainSearchComponent {


  private readonly searchChangeSubject = new Subject<string>()

  constructor(public service: HomeService) {
    this.searchChangeSubject.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      debounceTime(2000),
      tap(text => this.search(text)),
      takeUntilDestroyed()
    ).subscribe()
  }

  onSearchChange(value: string) {
    this.searchChangeSubject.next(value)
  }

  search(query: string) {
    this.service.executeSearch(query)
  }

  reset() {
    this.service.executeSearch('')
  }
}
